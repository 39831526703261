@use '@strategies/tokens/animation';

@use '@strategies/tokens/color';
@use '@strategies/tokens/font';
@use '@strategies/tokens/layout';


.Team {
    background: $color_brand !important;

    .content {
        > .Henshu__EditableRichText {
            margin: 0 0 $dims_padding-large;
            color: $color_brand--secondary !important;
            p {
                font-size: font.$size--large;
            }
        }
        .logosbanner {
             $_size: 275px;
            flex: 1 1;
            width: auto;
            height: 300px;
            background-position: center center;
            background-size: cover;
            margin-top: 2 * layout.$padding--large;

            @include respond-to(mobile) {
                width: auto;
                height: 100px;
                flex: 0 0 $_size;
                margin-top: 1 * layout.$padding--large;
            }
            @include respond-to(tablet) {
                width: auto;
                height: 260px;
                flex: 0 0 $_size;
            }

            @include respond-to(desktop) {
                margin-left: 20vw;
                width: 100vh;
                height: 300px;
                flex: 1 0 $_size;
            }
    }
    }

    .team {
        display: flex;
        flex-direction: column;
        gap: $dims_padding-medium;
        margin-top: $dims_padding-medium;
        margin-bottom: $dims_padding-medium;
    }
    
   

    .TeamMember {
        display: flex;
        flex-direction: column;
        @include respond-to(desktop) {
            flex-direction: row;

        }
        
        gap: layout.$padding--large;

        &-headshot {
            $_size: 275px;

            flex: 0 0 $_size;
            width: $_size;
            height: $_size;

            border-radius: 50%;
            border: 6px solid $color_brand--secondary;
            background-position: center center;
            background-size: cover;
        }

        &-content {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            margin: auto 0;

            h3 {
                font-size: 2rem;
                font-weight: 600;
            }

            h4 {
                color: $color_brand--secondary;
                font-weight: 600;
                font-size: $font_size-large;
                margin: layout.$padding--large 0;
            }

            p {
                margin: 0;
                font-size: font.$size--large;
            }
        }
    }

    .content section {
        margin-top: $dims_padding-large;
        padding: $dims_padding-large 0;

        h2 {
            margin-bottom: $dims_padding-large;
            color: $color_font-dark;
                font-size: $font_size-largest * .625;
                font-weight: 600;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            height: 100%;
            width: 200vw;
            left: -50vw;
            top: 0;
            background: color.$white;
        }
    }

}
