@use '@strategies/tokens/color';
@use '@strategies/tokens/font';
@use '@strategies/tokens/layout';


.Explore {
    background: none !important;

    h2, h3 {
        color: $color_black;
    }

    .content {
        position: static;

        > h2, > h3 {
            display: none;
        }
    }

    .header {
        padding: $dims_padding-medium 0;
        margin: 0;

        &:before {
            content: '';
            position: absolute;
            display: block;
            left: -50vw;
            width: 200vw;
            background: color.$white;
            height: 100%;
            top: 0;
        }

        > h3 {
            margin: 0;

            color: $color_font-dark;
            font-size: $font_size-medium;
            font-weight: 400;
        }

        > h2 {
            margin: $dims_padding-small 0 0;

            color: $color_font-dark;
            font-size: $font_size-largest * .625;
            font-weight: 600;
        }
    }


    &-image--mobile { 

        @include respond-to(desktop) {
            display: none;
        }
        position: absolute;
        left: 0%;

        width: 100vw;
        height: 100vh;
        padding: $dims_padding-medium $dims_padding-large;

        color: color.$white;

        background-size: cover;
        background-position: center center;
        h4 {
            padding-bottom: 50px;
        }
        h4, h5 {
            margin: 0;
            font-size: 3.75rem;
            font-weight: 900;
            
        }
        h5 {
            font-size: 2rem;

            &:nth-last-of-type(2n+1) {
                padding-bottom: 50px;
            }
        }
        .Section {
            position: absolute;
            top: 0;
            visibility: hidden;
            pointer-events: none;
        }

    }

    &-image {

        @include respond-to(mobile) {
            display: none;
        }
        position: absolute;
        left: 0%;

        width: 100vw;
        height: 100vh;
        padding: $dims_padding-medium $dims_padding-large;

        color: color.$white;

        background-size: cover;
        background-position: center center;

        h4 {
            margin: 0;
            font-size: 3.75rem;
            font-weight: 900;
        }

        .Section {
            position: absolute;
            top: 0;
            visibility: hidden;
            pointer-events: none;
        }
    }

    &-scroller {
        margin-top: 100vh;

        .Section main {
            margin-left: auto;
            margin-right: 0;
        }
    }
}
