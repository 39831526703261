@use '@strategies/tokens/color';
@use '@strategies/tokens/font';
@use '@strategies/tokens/layout';


.Goals {
    background: none !important;
    //background: $color_brand !important;

}
