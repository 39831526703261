@use "sass:math";
@use '@strategies/tokens/animation';
@use '@strategies/tokens/layout';


.MapSequence {
    $_width: 70vw;

    position: fixed;
    top: 0;

    width: 100%;
    height: 100%;
    pointer-events: none;

    .maps {
        display: flex;
        align-items: center;
        top: 0rem;
        left: 5%;
        transform: translateZ(0);

        height: 100%;
        width: 70vw;

        transition: left animation.$speed, width animation.$speed, top animation.$speed;

        @include respond-to(tablet) {
            left: 28%;
        }

        &.flip {
            @include respond-to(tablet) {
                left: 5% !important;
            }
        }
    }

    .map {
        position: absolute;
        top: 50%;
        transform: translateY(-45%) translateZ(0);

        width: $_width;
        height: math.div($_width, 1.56);

        @include respond-to(tablet) {
            left: 0;
            width: 100%;
        }
        @include respond-to(mobile) {
            left: 5%;
            width: 80vw;
            margin: auto;
        }
        margin: auto;

        opacity: 0;
        transition: opacity animation.$speed * 2,;
        pointer-events: none;

        text-align: center;
        background-size: cover;
        background-position: center center;
        border-radius: layout.$border-radius--large * 2;
        overflow: hidden;

        &.active {
            opacity: 1;
            pointer-events: all;
        }
    }

}
