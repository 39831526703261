@use '@strategies/tokens/animation';
@use '@strategies/tokens/color';
@use '@strategies/tokens/font';
@import '@strategies/tokens';

@import 'core';
@import 'vendor';

.logo {
    color: $color_brand;
    font-family: $font_family-secondary;
    font-size: $font_size-larger;

    span {
        font-family: $font_family-primary;
        font-weight: 100;
    }
}

.App {
    background: $color_brand;
    transition: background animation.$speed--slow;

    &.white {
        background: color.$white;
    }

    .anchor-item:first-of-type {
        display: none;
    }

    .scroller {
        pointer-events: none;
    }

    .NavSection {
        padding: $dims_padding-medium;

        @include respond-to(tablet) {
            padding: $dims_padding-medium $dims_padding-large;
        }

        &:nth-of-type(2n+1) {
            background: $color_bg-light-grey;
        }

        &:nth-of-type(2n) {
            background: color.$white;
        }

        &.TeamUpdate {
            background: $color-brand-action !important;
            .content {
                > h3 {
                    margin: 0;
    
                    color: $color_bg-light-grey;
                    font-size: $font_size-medium;
                    font-weight: 400;
                }
    
                > h2 {
                    margin: $dims_padding-small 0 $dims_padding-medium;
    
                    color: $color_bg-light-grey;
                    font-size: $font_size-largest * .625;
                    font-weight: 600;
                }
    
                > p {
                    color: $color_bg-light-grey;
                    line-height: 1.5em;
                    font-size: font.$size--xsmall * 2;
                }

                > .Henshu__EditableRichText {
                    // margin: 0 0 $dims_padding-large;
                    color: $color_bg-light-grey;
                    p {
                        // font-size: font.$size--large;
                    }
                }
            }
        }

        .content {
            > h3 {
                margin: 0;

                color: $color_font-dark;
                font-size: $font_size-medium;
                font-weight: 400;
            }

            > h2 {
                margin: $dims_padding-small 0 $dims_padding-medium;

                color: $color_font-dark;
                font-size: $font_size-largest * .625;
                font-weight: 600;
            }

            > p {
                line-height: 1.5em;
                font-size: font.$size--xsmall * 2;
            }
        }
    }

    button,
    .button {
        &.with-arrow {
            &:after {
                content: url(./assets/arrow-white.svg);
                display: inline-block;
                vertical-align: middle;
                top: 1px;
                margin-left: $dims_padding-small * 1.5;
                transform: rotate(0deg);
            }

            &.blue:after {
                content: url(./assets/arrow-brand.svg);
                transform: rotate(0deg);
            }
        }
    }

    footer {
        width: 100%;
        padding: $dims_padding-small $dims_padding-medium;

        background: $color-brand;

        @include respond-to(tablet) {
            padding: $dims_padding-small $dims_padding-large;
        }



        .logo {
            display: inline-flex;
            align-items: center;
            gap: $dims_padding-small;

            img {
                height: $font_size-medium;

                @include respond-to(tablet) {
                    height: $font_size-larger;
                }
            }

            h3 {
                max-width: 10em;
                margin: auto;
                font-size: $font_size-medium;
                font-weight: 400;
                font-family: $font_family-primary;
                color: color.$white;
            }
        }

        button {
            position: absolute;
            right: $dims_padding-medium;
            top: 50%;
            transform: translateY(-50%);

            width: 2rem;
            height: 2rem;
            margin: auto;

            @include respond-to(tablet) {
                width: 3.125rem;
                height: 3.125rem;
            }

            background: $color-brand-hover-action;
            border: none;
            border-radius: 50%;
            box-shadow: $box_shadow;
            cursor: pointer;

            img {
                width: 70%;
                top: 2px;
                transform: rotate(-90deg);
                transition: top .2s;
            }

            &:hover {
                img {
                    top: -1px;
                }
            }
        }
    }
}

@import 'components/Section/Section';
@import 'components/MapSequence/MapSequence';
@import 'components/Contact/Contact';
@import 'components/ContactForm/ContactForm';
@import 'components/Welcome/Welcome';
@import 'components/Goals/Goals';
@import 'components/Explore/Explore';
@import 'components/Team/Team';
