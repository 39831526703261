@use '~@strategies/tokens/animation';
@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';


.Welcome {

    &-bigtitle {
        
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 1 * layout.$padding--large;
        @include respond-to(desktop) {
        padding-bottom: 5 * layout.$padding--large;
    }

        .Henshu__EditableRichText {
            flex: 1 1 auto;
            min-width: 25%;

            text-align: center;
            h1, h2 {
                color: color.$white;
                font-weight: 900;
                font-size: 4.7rem;
                margin: 0;
                text-align: center;
            }
            
            h2 {
                font-size: 1.5rem;

            }
        }
    }
    .metrics {
        display: flex;
        flex-wrap: wrap;
        gap: $dims_padding-medium;

        .metric {
            flex: 1 1 auto;
            min-width: 25%;

            text-align: center;

            h2 {
                color: color.$white;
                font-weight: 900;
                font-size: 3.75rem;
                margin: 0;
            }

            p {
                color: color.$white;
                font-weight: 700;
                font-size: font.$size--large;
            }
        }
    }


    &.NavSection {
        display: flex;
        flex-direction: column;
        align-items: center;

        min-height: 100vh;
        padding: $dims_padding-small 0 0;
        @include respond-to(desktop){
        padding: $dims_padding-large 0 0;
}
        background-image: url(../../assets/banner.jpg) !important;
        background-size: cover !important;
        background-position: center center !important;

        .content {
            top: 100px;
            width: 100%;
        }
    }

    &-wrap {
        footer {
            display: none;
            position: absolute;
            bottom: 0em;

            margin: auto;

            font-size: $font_size-medium;
            text-align: center;

            background: none;
            cursor: pointer;
            opacity: 1;
            pointer-events: all;
            transition-delay: 1s;
            transition: opacity animation.$speed, transform animation.$speed;
            transform: translateY(0em);

            @include respond-to(tablet) {
                display: block;
            }

            &.hidden {
                opacity: 0;
                transform: translateY(-1em);
                transition-delay: 0s;
                pointer-events: none;
            }

            p {
                color: color.$white;
            }

            &:hover {
                svg {
                    transform: translateY(.1em);
                }
            }

            svg {
                $size: $font_size-large * 3;

                width: $size;
                height: $size;
                margin: auto;
                color: color.$white;
                transform: translateY(0em);
                transition: transform animation.$speed * .5;
            }
        }
    }
}
